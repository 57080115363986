// Registration
export const REGISTER_SUCCESSFUL = 'REGISTER_SUCCESSFUL';

// User session
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGIN_UNSUCCESSFUL = 'LOGIN_UNSUCCESSFUL';
export const LOGOUT_USER = 'LOGOUT_USER';

// Expenses
export const REFRESH_EXPENSES = 'REFRESH_EXPENSES';
export const GET_USER_EXPENSES = 'GET_USER_EXPENSES';
export const GET_USER_CATEGORIES = 'GET_USER_CATEGORIES';
export const ADD_NEW_EXPENSE = 'ADD_NEW_EXPENSE';
export const EDIT_EXPENSE = 'EDIT_EXPENSE';
export const DELETE_EXPENSE = 'DELETE_EXPENSE';
export const GET_MONTHLY_TOTAL = 'GET_MONTHLY_TOTAL';
export const GET_YEARLY_TOTAL = 'GET_YEARLY_TOTAL';
export const GET_CATEGORY_AMOUNTS = 'GET_CATEGORY_AMOUNTS';

// Groups
export const GET_GROUPS = 'GET_GROUPS';
export const ADD_GROUP = 'ADD_GROUP';
export const EDIT_GROUP = 'EDIT_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const SET_DEFAULT_GROUP = 'SET_DEFAULT_GROUP';
export const GET_GROUP_AMOUNTS = 'GET_GROUP_AMOUNTS';
