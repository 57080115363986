import React, { Component } from 'react';

class Terms extends Component {
  state = {};
  render() {
    return <div>Terms and Conditions Page</div>;
  }
}

export default Terms;
